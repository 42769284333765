import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

import projectLogo from "../download/logo.png";
// import projectTimeshTemplate from "../download/Minutestemplate.docx"; // commented out for preliminary site release, resource unavailable

export default function DownloadPage() {
    return (
        <>
            <Header />
            <Main>
                <div className="Article BorderTop">
                    <h2>Download</h2>
                    <p><a href={projectLogo} download="logo.png">Project logo</a></p>
                    {/*
                    <p>
                        <a href={projectTimeshTemplate}>Project timesheet template</a>
                    </p>
                    // Commented out for the same reason as above
                    */}
                </div>
            </Main>
            <Footer />
        </>
    );
}
